.cardContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  position: relative;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    padding-left: 50px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .filterButtonsContainer {
      display: flex;
      column-gap:50px;
      align-items: center;

      .filterButton {
        // min-width: 60px;
        padding: 8px 15px;
        border-radius: 5px;
        border: none;
        outline: none;
        background-color: transparent;
        color: #000;
        font-family: "Nunito Regular";
        font-size: 14px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;


        &:hover {
          color: #3559CC;
        }
      }

      .activeButton {
        color: #3559CC;
        background-color: #ebf0ff;
      }
    }

    .title {
      font-family: "Nunito Bold";
      color: #000;
      font-size: 24px;
    }
    .connecting {
      color: #7b7b7b;
    }
    .connected {
      color: #06c355;
    }
  }
  .textAreaContainer {
    width: 100%;
    padding: 0 50px;
    position: relative;

    .placeholder {
      position: absolute;
      display: flex;
      align-items: center;
      left: 70px;
      top: 20px;
      font-family: "Nunito Regular";
      color: #7b7b7b;
      column-gap: 15px;
    }

    textarea {
      width: 100%;
      height: 200px;
      outline: none;
      border: 1px solid #dedede;
      font-family: "Nunito Regular";
      font-size: 16px;
      color: #7b7b7b;
      border-radius: 5px;
      padding: 20px;
      resize: none;
      transition: all 0.3s ease-in-out;

      &:focus {
        border: 1px solid #3658cc;
        background-color: #f8f9fa;
      }
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 20px 50px;

    .uploadStatus {
      font-family: "Nunito Regular";
    }
  }
  .chatsWrapper {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .conversationSection {
      border-right: 1px solid #eeeeee;
      width: 30%;
      height: 100%;

      .convoWrapper {
        overflow-y: overlay;
        scroll-behavior: smooth;

        .conversation {
          outline: none;
          border: none;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          padding: 12px;
          flex-direction: column;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          width: 100%;
          background-color: #fff;

          &:hover {
            background-color: #f2fafc;
          }

          .convoEmail {
            font-family: "Nunito Light";
            font-size: 12px;
          }

          .convoMsg {
            margin-top: 15px;
            font-family: "Nunito Regular";
          }
        }
      }
    }

    .messagesSection {
      display: flex;
      width: 70%;
      height: 100%;
      position: relative;

      .messageScroller {
        flex-direction: column;
        overflow-y: overlay;
        scroll-behavior: smooth;
        width: 100%;
        height: calc(100% - 256px);

        .chatBalloonContainer {
          padding: 20px;
          padding-bottom: 100px;
        }

        .receivedMsgBalloonWrapper {
          justify-content: flex-start !important;

          .chatBalloon {
            background-color: #dbdbdb !important;
            color: #000 !important;
            text-align: left !important;
            border-radius: 20px 20px 20px 0 !important;

            .senderName {
              left: 0;
              bottom: -28px;
            }
          }
        }

        .balloonWrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .chatBalloon {
            padding: 20px;
            min-width: 100px;
            background-color: #3558cc;
            color: #fff;
            text-align: right;
            border-radius: 20px 20px 0 20px;
            position: relative;
            margin-bottom: 38px;

            .senderName {
              right: 0;
              bottom: -28px;
              position: absolute;
              color: #000;
            }
          }
        }
      }

      .messageInputSecion {
        height: auto;
        border-top: 1px solid #eeeeee;
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 20px;
        align-items: flex-end;
        display: flex;
        row-gap: 20px;
        flex-direction: column;

        .messageInput {
          resize: none;
          min-height: 150px;
          width: 100%;
          border: 1px solid #f1f1f1;
          border-radius: 20px;
          padding: 12px;
          outline: none;

          &:focus {
            border: 1px solid #3558cc;
          }

          &:disabled {
            background-color: #b3b3b3;
          }
        }

        .sendButton {
          width: 100px;
          height: 45px;
          color: #fff;
          background-color: #3558cc;
          border-radius: 8px;
          border: none;
          outline: none;

          &:disabled {
            background-color: #364575;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .cardContainer {
    .cardDropDowns {
      padding: 0 20px;
      column-gap: 20px;
    }
    .cardHeader {
      padding-left: 20px;
      padding-right: 20px;
    }
    .textAreaContainer {
      padding: 0 20px;
    }
    .buttonContainer {
      padding: 20px 20px;
    }
  }
}
