.row {
  display: flex;
  justify-content: space-between;
}
.miscsContainer {
  width: 100%;
  padding: 20px;
}
.buttonRow {
  display: flex;
  align-items: center;
  min-height: 50px;
  column-gap: 94px;
  width: 100%;
}
.couponRow {
  display: flex;
  align-items: center;
  column-gap: 86px;
  margin-top: 20px;
  align-items: center;

  .couponInputContainer{
    height: 50px;
  }
}
