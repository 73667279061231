.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 41px;
  margin: auto;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #3557cc;
    color: #fff;
    font-family: "Nunito Regular";
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    outline: none;
    border: none;

    &:hover {
      background-color: rgb(41, 70, 143);
    }
  }
}

@media screen and (max-width: 768px) {
  .buttonContainer {
    width: 100%;
    min-width: 43px;
    height: 40px;
  }
}
