.wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: column;
  display: flex;
  row-gap: 20px;

  .container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    
    .input {
      width: 100%;
    }
  }
}
