.pricingContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .pricingColumn {
    padding: 30px;
    width: 100%;
    position: relative;

    .addButton {
      display: flex;
      background-color: transparent;
      border: none;
      outline: none;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 26px;
      height: 26px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:nth-child(1) {
      border-right: 1px solid #eaeaea;
    }

    .prices {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      row-gap: 10px;
      margin-top: 30px;

      .pricingItem {
        width: 100%;
        height: 60px;
        background-color: #fff;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        position: relative;
        color: #000;
        font-size: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 18px;

        &:hover {
          background-color: #f8f9fa;
        }

        .description {
          padding: 18px;
          font-size: 12px;
          color: #787878;
          font-family: "Nunito Regular";
        }
      }
    }
  }
}
.closeButton {
  position: absolute;
  right: 18px;
  top: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(217, 217, 217, 0.28);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;

  .popupContent {
    width: 90%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    position: relative;

    .inputContainer {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .textInputContainer {
        height: 50px;
      }
    }
    .buttonContainer {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
    }
  }
}
