.topNav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 71px;
  padding: 0 70px;
  background-color: #fff;
  border-bottom: 1px solid #edeff1;

  .menuButton {
    display: none;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    padding: 6px;
    min-width: 36px;
    min-height: 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3px;
    margin-right: 15px;
  }

  .searchboxWrapper {
    width: 100%;
    margin-right: 10px;
  }
  .topNavButton {
    min-width: 36px;
    min-height: 36px;
    border: 1px solid #e9ecef;
    margin: 0 5px;
    border-radius: 5px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
}
@media screen and (max-width: 768px) {
  .topNav {
    padding: 0 20px;

    .menuButton {
      display: flex;

      .line {
        width: 10px;
        height: 2px;
        background-color: rgb(172, 172, 172);
      }
    }
  }
}
