.cardContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 24px;
  position: relative;

  .cardDropDowns {
    display: flex;
    align-items: center;
    padding: 0 50px;
    flex-wrap: wrap;
    height: 100px;
    column-gap: 50px;

    .checkBox {
      cursor: pointer;

      .checkDiv {
        display: "flex";
        background: #3557cc;
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
    }
  }

  .tableWrapper {
    position: relative;
  }
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    padding-left: 50px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .title {
      font-family: "Nunito Bold";
      color: #000;
      font-size: 24px;
    }
  }
}

.notSubscribedCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.downloadCSV {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: #3557cc;
  column-gap: 10px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out all;

  &:hover {
    text-decoration: underline;
  }
}

.disabledSpan{
  color: #6f6f6f;
  text-decoration: line-through;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    .cardDropDowns {
      padding: 0 20px;
      column-gap: 20px;
      height: auto;
      padding: 20px 20px;
      row-gap: 8px;
    }
    .cardHeader {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
